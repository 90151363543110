import React from "react";
import NewDepForm from "./NewDepForm";

import "./_NewDepForm.scss";
const AddNewDep = ({ handleClose, id }) => {
  return (
    <div>
      <NewDepForm handleClose={handleClose} id={id} />
    </div>
  );
};

export default AddNewDep;
